.btn {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  border-radius: 15px 0;
  justify-content: center;
  align-items: center;
  font-family: Righteous, sans-serif;
  font-weight: normal;
  text-decoration: none;
  display: flex;
}

/*# sourceMappingURL=index.fc90b7aa.css.map */
