@import "constants";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $header-font;
  font-weight: normal;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  letter-spacing: 5px;

  border-radius: 15px 0;
}
